.cubeLoader {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
}

.tableFilter {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.postsPerPage {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.spaceRolesContainer {
  overflow: hidden;
  overflow-y: auto;
  max-height: 24vh;
  padding: 5px 10px;
  margin-bottom: 12px;
  background: ghostwhite;
}
