.cubeLoader {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
}

.collectionName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
