:root {
  --sk-size: 40px;
  --sk-color: #333;
}

.sk-center {
  margin: auto;
}

.sk-fold {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  transform: rotateZ(45deg);
}

.sk-fold-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-fold-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--sk-color);
  animation: sk-fold 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-fold-cube:nth-child(2) {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-fold-cube:nth-child(4) {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-fold-cube:nth-child(3) {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-fold-cube:nth-child(2):before {
  animation-delay: 0.3s;
}

.sk-fold-cube:nth-child(4):before {
  animation-delay: 0.6s;
}

.sk-fold-cube:nth-child(3):before {
  animation-delay: 0.9s;
}

@keyframes sk-fold {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.sk-wander {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-wander-cube {
  background-color: var(--sk-color);
  width: 20%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  --sk-wander-distance: calc(var(--sk-size) * 0.75);
  animation: sk-wander 2s ease-in-out -2s infinite both;
}

.sk-wander-cube:nth-child(2) {
  animation-delay: -0.5s;
}

.sk-wander-cube:nth-child(3) {
  animation-delay: -1s;
}

@keyframes sk-wander {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }

  50% {
    transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-179deg);
  }

  50.1% {
    transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-180deg);
  }

  75% {
    transform: translateX(0) translateY(var(--sk-wander-distance))
      rotate(-270deg) scale(0.6);
  }

  100% {
    transform: rotate(-360deg);
  }
}

:root {
  --sk-size: 30px;
  --sk-color: #fff;
}

.sk-wave {
  width: 40px;
  white-space: nowrap;
}

.sk-fading-circle .sk-circle {
  margin-top: 0;
  margin-bottom: 0;
}

.sk-wave {
  width: 40px;
  white-space: nowrap;
}

.sk-fading-circle .sk-circle {
  margin-top: 0;
  margin-bottom: 0;
}
