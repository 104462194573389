.tableFilter {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.postsPerPage {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
