a.menu-link.menu-toggle {
  color: #c9c8ce;
}
span.app-brand-text.demo.menu-text.fw-bold {
  color: #dbdade;
}

i.ti.menu-toggle-icon.d-none.d-xl-block.ti-sm.align-middle {
  color: #c9c8ce;
}

.bg-menu-theme {
  background-color: #242745 !important;
  color: #c9c8ce;
}
a.menu-link {
  color: #c9c8ce !important;
}
.bg-menu-theme.menu-vertical .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(
    72.47deg,
    #7367f0 22.16%,
    rgba(115, 103, 240, 0.7) 76.47%
  );
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48);
  color: #fff !important;
}
li.menu-item.active a {
  background: linear-gradient(
    72.47deg,
    #7367f0 22.16%,
    rgba(115, 103, 240, 0.7) 76.47%
  ) !important;
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48) !important;
  color: #fff !important;
}
span.dropdown-shortcuts-icon.rounded-circle.mb-2 {
  background: #242745 !important;
  color: white !important;
}
.card-body {
  padding: 16px 24px;
}
.ProjectBox {
  border: 1px dashed #efefef;
  border-radius: 5px;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}
.ProjectBox .badge {
  position: absolute;
  right: 30px;
  top: 15px;
  line-height: 13px;
}
.bLight1Primary {
  background-color: rgba(122, 112, 186, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark,
    warning;
}
.bLight1Success {
  background-color: rgba(13, 167, 89, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark,
    warning;
}
.bLight1Primary .badge,
.bLight1Success .badge,
.badge {
  float: right;
}
.img20 {
  width: 20px !important;
}
.roundedCircle {
  border-radius: 50% !important;
  height: 100%;
}
.customers ul {
  display: inline-block;
}
.d-inline-block {
  display: inline-block !important;
}
.customers ul li img {
  border: 2px solid white;
  transition: 0.5s;
}
.img30 {
  width: 30px !important;
}
.roundedCircle {
  border-radius: 50% !important;
}
.customers {
  text-align: center;
}
.spaceBadge {
  float: right;
}
.description {
  min-height: 100px;
}
.cubeLoader {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
}
