.error .header {
  background-color: #f44336;
  color: white;
  font-weight: 500;
  border-radius: 6px 6px 0 0;
}

.error .body {
  background-color: #fddede;
  color: #b71c1c;
  border-radius: 0 0 6px 6px;
}

.success .header {
  background-color: #4caf50;
  color: white;
  font-weight: 500;
  border-radius: 6px 6px 0 0;
}

.success .body {
  background-color: #d4edda;
  color: #155724;
  border-radius: 0 0 6px 6px;
}

.toast {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  padding: 12px;
  border-radius: 6px;
}

.toast-header {
  padding: 10px;
}

.toast-body {
  padding: 10px;
  font-size: 14px;
}

.btn-close {
  opacity: 0.8;
}

.btn-close:hover {
  opacity: 1;
}

.notificationToast {
  z-index: 9999;
}

@keyframes bounceIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  60% {
    transform: translateX(-20px);
    opacity: 1;
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounceOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.notificationToast {
  animation: bounceIn 0.7s ease forwards;
  transition: opacity 0.5s ease;
}

.slideOut {
  animation: bounceOut 0.7s ease forwards;
}
