.normalEditor {
  margin-top: -35px;
}

.normalEditor button {
  position: relative;
  top: 50px;
  z-index: 10;
  right: 12px;
}

.fullscreenEditor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1e1e1e;
  z-index: 9999;
  padding: 10px 28px 0px 10px;
}
