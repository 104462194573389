.page_msg {
  /* font-weight: 500; */
  padding-top: 0.5rem;
  font-size: 0.8125rem;
}

.pagination_container {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  float: right;
}

.pagination_item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2b3d51;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.pagination_item:hover {
  z-index: 2;
  color: #fff;
  background: #a20067;
  border-color: #a20067;
}

.selected {
  background: #a20067;
  color: #fff;
  border: 1px solid #a20067;
}
.disabled {
  pointer-events: none;
}

@media screen and (max-width: 991px) {
  .pagination_container {
    display: block;
    float: none;
  }
}

.paginate {
  float: right;
}
