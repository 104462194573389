.banner {
  background-repeat: "no-repeat";
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  height: 133px;
}
.heading {
  margin: 0 auto;
}
.heading h2 {
  color: #dbdade !important;
}
