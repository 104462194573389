.cubeLoader {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.3s; /* 0.5s for the shake duration */
}
