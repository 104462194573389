.searchLabel {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 400;
}
#storesFilter {
  border-radius: 30px;
  padding: 5px 20px 5px 15px;
  outline: none;
  border: 1px solid #dee2e6;
  max-width: 100%;
}
