.sidebar {
  width: 60px;
  height: 100vh;
  background-color: #242745;
  overflow-x: hidden;
  transition: width 0.3s ease;
  color: #ffffffc4;
  position: fixed;
  z-index: 10000;
}

.expanded {
  width: 240px;
}

.collapsed {
  width: 70px;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.logoLink {
  text-decoration: none;
  color: #ffffffc4;
}

.menuList {
  list-style: none;
  padding: 0px 10px;
  margin: 0;
}

.compressedMenuList {
  list-style: none;
  padding: 0px 5px;
  margin: 0;
}

.menuHeader {
  font-size: 0.75rem;
  font-weight: bold;
  color: #999;
  padding: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.menuItem {
  position: relative;
}

.menuLink {
  display: flex;
  align-items: center;
  color: #ffffffc4;
  padding: 0.75rem 1rem;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background 0.3s;
  font-weight: 500;
}

.menuLink:not(.active):hover {
  background: linear-gradient(2.47deg, #b6b2e40e 22.16%, #7267f018 6.47%);
  box-shadow: 0 2px 6px 0 #7267f02d;
  color: #ffffffc4 !important;
}

.menuText {
  margin-left: 2px;
  display: inline-block;
  white-space: nowrap;
}

.active {
  background: linear-gradient(72.47deg, #7367f0 22.16%, #7367f0b3 76.47%);
  box-shadow: 0 2px 6px 0 #7367f07a;
  color: #fff !important;
  border-radius: 5px;
  margin: 1px 2px;
}

.subMenu {
  list-style: none;
  padding-left: 1rem;
}

.subMenu .menuLink {
  padding-left: 2rem;
  font-size: 0.85rem;
  color: #ccc;
}

.subMenu .menuLink:hover {
  color: #ffffffc4;
}

.ml20 {
  margin-left: 20px;
}
